import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/scss/main.scss";
import TrackingService from "@/services/TrackingService.js";
import UserService from "./services/UserService";

Vue.config.productionTip = false;

var defaultHeight = 1080;
var defaultWidth = 1920;

// Import plugins
import "@/plugins/LivehouseComponentLibrary";
import "@/plugins/Components";

import vuetify from "./plugins/vuetify";

// EventCDN SDK
import { createEventcdnSdk } from "@livehouse/eventcdn-sdk-js";

const eventcdn = createEventcdnSdk({});

// Add custom services
Vue.$livehouse.eventcdn = eventcdn;
Vue.prototype.$livehouse.eventcdn = eventcdn;
Vue.prototype.$tracking = TrackingService;
Vue.$tracking = TrackingService;
Vue.prototype.$users = UserService;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

console.log("ENV:", process.env);
