import Vue from "vue";
import LivehouseComponentLibrary from "@livehouse/vue-component-library";

const COLORS = {
  white: "#FFFFFF",
  pink: "#FFCAD4",
  "lighter-grey": "#F4F4F4",
  "light-grey": "#E8E8E8",
  grey: "#B7B8B9",
  "dark-grey": "#808080",
  "darker-grey": "#29252C",
  black: "#000000",
  red: "#BA0000",
};

Vue.use(LivehouseComponentLibrary, {
  mode: "dark",
  fonts: {
    body: '"Pan Text", "Roboto", "sans-serif"',
    heading: '"Pan Text", "Roboto", "sans-serif"',
  },
  theme: {
    themes: {
      light: {
        text: "#000",
      },
      dark: {
        primary: COLORS.pink,

        ...COLORS,
      },
    },
  },
  breakpoint: {
    thresholds: {
      sm: 1020,
    },
  },
});
