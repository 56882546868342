import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import LivehouseComponentLibrary from "@livehouse/vue-component-library";

Vue.use(Vuetify);

/*export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
});
*/
export default LivehouseComponentLibrary.createVuetifyInstance(Vuetify);
