<template>
  <button @click.prevent="onClick($event)">
    <slot></slot>
  </button>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(["event"]),
  },
  methods: {
    async onClick($event) {
      if (this.event.state !== "ONDEMAND") {
        await axios.get(this.href);
      }

      this.$emit("click", $event);

      window.open(this.href, "_blank");
    },
  },
};
</script>

<style>
</style>