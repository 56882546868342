import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: {
      name: "Lobby",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lobby",
    name: "Lobby",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lobby" */ "../views/Lobby.vue"),
    meta: {
      requiresAuth: true,
      name: "Lobby",
    },
  },
  {
    path: "/theatre",
    name: "Theatre",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "theatre" */ "../views/Theatre.vue"),
    meta: {
      requiresAuth: true,
      name: "Theatre",
    },
  },
  {
    path: "/information",
    name: "Information",
    component: () =>
      import(/* webpackChunkName: "information" */ "../views/Information.vue"),
    meta: {
      requiresAuth: true,
      name: "Information",
    },
  },

  {
    path: "/meet-your-colleagues",
    name: "MeetYourColleagues",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "meet-your-colleagues" */ "../views/MeetYourColleagues.vue"
      ),
    meta: {
      requiresAuth: true,
      name: "Meet your colleagues",
    },
  },

  {
    path: "/lounge",
    name: "Lounge",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lounge" */ "../views/Lounge.vue"),
    meta: {
      requiresAuth: true,
      name: "Lounge",
    },
  },
  {
    path: "/tour-de-pandora",
    name: "TourDePandora",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Tour de pandora" */ "../views/TourDePandora.vue"
      ),
    meta: {
      requiresAuth: true,
      name: "Tour de pandora",
    },
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "My profile" */ "../views/MyProfile.vue"),
    meta: {
      requiresAuth: true,
      name: "My profile",
    },
  },
  {
    path: "/user-page/:id",
    name: "UserPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "User page" */ "../views/UserPage.vue"),
    meta: {
      requiresAuth: true,
      name: "User page",
    },
  },
  {
    path: "/destination/:id",
    name: "Destination",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Destination" */ "../views/Destination.vue"),
    meta: {
      requiresAuth: true,
      name: "Destination",
    },
  },
  {
    path: "/session",
    name: "Session",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Session" */ "../views/Session.vue"),
    meta: {
      requiresAuth: true,
      name: "Session",
    },
  },
  {
    path: "/sessions/:id",
    name: "SessionDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "theatre" */ "../views/SessionDetails.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/breakoutrooms/:id",
    name: "BreakoutRoom",
    component: () =>
      import(/* webpackChunkName: "breakout" */ "../views/BreakoutRoom.vue"),
    meta: {
      requiresAuth: true,
      name: "Breakout room",
    },
  },
  {
    path: "/games/pandora-man",
    name: "PandoraMan",
    component: () =>
      import(/* webpackChunkName: "games" */ "../views/PandoraMan.vue"),
    meta: {
      requiresAuth: true,
      name: "Pandora man",
    },
  },
  /* Authentication */
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunName: 'admin' */ "../views/Login.vue"),
    meta: { guest: true, name: "Login" },
  },
  /* Admin */
  {
    path: "/admin",
    name: "AdminHome",
    component: () =>
      import(/* webpackChunName: 'admin' */ "../views/Admin/AdminHome.vue"),
    meta: {
      requiresAdmin: true,
      name: "Admin home",
    },
  },
  {
    path: "/admin/manager",
    name: "AdminManager",
    component: () =>
      import(/* webpackChunkName: 'admin' */ "../views/Admin/AdminManager.vue"),
    meta: {
      requiresAdmin: true,
      name: "Pandora SMC 2021 Event Manager",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  /* eslint-disable no-unused-vars */
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  /* eslint-enable no-unused-vars */

  routes,
});

// SSO user
router.beforeEach(async (to, from, next) => {
  console.log("Has SSO Failed:", store.state.ssoFailed);
  if (!store.getters.me && !store.state.ssoFailed) {
    await store.dispatch("sso");
  }

  next();
});

// Handle dynamic redirect
router.beforeEach((to, from, next) => {
  let redirect = null;

  //console.log("To:", to);

  to.matched.forEach((record) => {
    if (record.meta.redirect) {
      redirect = record.meta.redirect;
    }
  });

  //console.log("Redirect:", redirect, "isAdmin", store.getters.isAdmin);
  // Admins have access to live page
  if (redirect && !store.getters.isAdmin) {
    //console.log("Redirecting");
    next({ name: redirect });
  } else {
    next();
  }
});

// Add navigation guard for pages requiring login
router.beforeEach((to, from, next) => {
  //console.log("Auth guard:", "To:", to, "from:", from);

  if (
    to.matched.some(
      (record) => record.meta.requiresAuth || record.meta.requiresAdmin
    )
  ) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next({ name: "Login", query: { next: to.fullPath } });
    }
  } else {
    next();
  }
});

// Add navigation guard for admin pages
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters.isAdmin) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

// Add redirect for guest-only pages
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({ name: "Home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
