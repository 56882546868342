<template>
  <v-dialog
    v-model="dialog"
    persistent
    min-width="450"
    max-width="450"
    overlay-opacity="0"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        v-show="show"
        v-bind="attrs"
        v-on="on"
        :style="{
          position: 'fixed',
          top: top,
          left: left || null,
          right: right || null,
        }"
      >
        <v-img
          class="monogram"
          src="https://vod.eventcdn.net/pandora/smc2021/HiddenMonogram_100__2x.png"
          style="height: 50%; width: 50%"
        ></v-img>
      </button>
    </template>

    <v-card style="background: black; border: 1px solid var(--dark-grey)">
      <v-card-title class="secret-question-title pink--text">
        <div
          class="d-flex align-center justify-space-between"
          style="width: 100%"
        >
          <div class="d-flex align-center">
            <v-img
              class="mr-4"
              contain
              max-height="15"
              max-width="15"
              src="https://vod.eventcdn.net/pandora/smc2021/Pandora_Monogram.svg"
            ></v-img>

            <template v-if="!hasAnswered">
              {{ title }}
            </template>

            <template v-if="hasAnswered && answerIsCorrect">
              Congratulations!
            </template>

            <template v-if="hasAnswered && answerIsCorrect === false">
              Better luck next time!
            </template>

            <template v-if="hasAnswered && answerIsCorrect === null">
              You have answered this question
            </template>
          </div>

          <v-btn icon dark color="pink" @click="dialog = false">
            <v-icon> fas fa-times-circle </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="secret-question-card-text">
        <template v-if="!hasAnswered">
          {{ question }}
        </template>

        <template v-if="hasAnswered && answerIsCorrect === null">
          {{ messages.isAnswered }}
        </template>

        <template v-if="hasAnswered && answerIsCorrect === false">
          {{ messages.error }}
        </template>

        <template v-if="hasAnswered && answerIsCorrect === true">
          {{ messages.correct }}
        </template>
      </v-card-text>

      <template v-if="!hasAnswered && options && answer">
        <v-form @submit.prevent="onSubmit">
          <v-radio-group
            v-model="usersAnswer"
            column
            class="secret-question-radio-buttons-container pb-0 mt-0"
          >
            <v-radio
              v-for="(option, idx) in options"
              :key="idx"
              class="secret-question-radio-buttons"
              :label="option"
              :value="option"
            ></v-radio>
          </v-radio-group>

          <v-card-actions class="secret-question-submit-button-container">
            <v-btn
              color="pink"
              class="mr-4 black--text font-weight-bold"
              type="submit"
              depressed
              :disabled="!usersAnswer || isLoading"
              :loading="isLoading"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-form>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "Get 15 pts. for a correct answer",
    },
    question: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
    answer: {
      type: String,
      required: false,
    },
    "tracking-id": {
      type: Number,
      required: false,
    },
    top: {
      type: String,
      required: true,
    },
    left: {
      type: String,
      required: false,
    },
    right: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      show: false,
      isLoading: false,
      dialog: false,
      hasAnswered: false,
      answerIsCorrect: null,

      usersAnswer: null,
    };
  },

  async mounted() {
    this.hasAnswered = this.getHasAnwered();

    if (!this.options) {
      await this.onSubmit();
    }

    setTimeout(() => (this.show = true), 2000);
  },

  computed: {
    ...mapState(["event"]),
    messages() {
      const messages = {
        correct: "",
        isAnswered: "Discover other hidden questions throughout the site.",
        error:
          "The answer is not right - try your luck with the other hidden questions found throughout the site.",
      };

      if (!this.options) {
        messages.correct =
          "You found one of the hidden monograms and gained 15 pts!";
      } else {
        messages.correct = "You answered right and gained 15 pts!";
      }

      return messages;
    },
  },

  methods: {
    getHasAnwered() {
      const hasAnswered = JSON.parse(
        localStorage.getItem(`hidden-question-${this.trackingId}-is-answered`)
      );

      return hasAnswered ?? false;
    },

    markAnswered() {
      localStorage.setItem(
        `hidden-question-${this.trackingId}-is-answered`,
        true
      );
    },

    async onSubmit() {
      this.isLoading = true;

      if (!this.options || this.usersAnswer === this.answer) {
        console.log("Answer is correct");
        // Track
        if (this.event.state !== "ONDEMAND") {
          await this.$tracking.trackPoint(this.trackingId);
        }

        this.answerIsCorrect = true;
        this.markAnswered();
      } else {
        console.log("Answer is incorrect");
        this.answerIsCorrect = false;
        this.markAnswered();
      }

      this.hasAnswered = this.getHasAnwered();

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.monogram {
  opacity: 0.1;
  transition: ease 0.3s;
}

.monogram:hover {
  opacity: 0.5;
}
</style>