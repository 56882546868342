import axios from "axios";

const BASE_TRACKING_URL = `${process.env.VUE_APP_TRACKING_URL}`;

export default {
  async trackPoint(id) {
    const url = `https://pandora-smc2021.portal.eventcdn.net/tracking/${id}/`;

    try {
      await axios.get(url);
    } catch (err) {
      console.error(
        "Failed to track points for link with id:",
        id,
        "- Are you sure it exists?"
      );
    }
  },

  async trackPage(id) {
    const url = `${BASE_TRACKING_URL}${id}/`;

    let response = null;

    try {
      response = await axios.get(url, { withCredentials: true });
    } catch (err) {
      console.error("Could not track page with id:", id);
    }
    return response;
  },
  get pageExportUrl() {
    return `${BASE_TRACKING_URL}export/`;
  },
  async trackDownload(id) {
    const url = `${BASE_TRACKING_URL}downloads/${id}/`;

    let response = null;

    try {
      response = await axios.get(url, { withCredentials: true });
    } catch (err) {
      console.error("Could not track page with id:", id);
    }
    return response;
  },
  get downloadExportUrl() {
    return `${BASE_TRACKING_URL}downloads/export/`;
  },
  get registrationExportUrl() {
    return `${BASE_TRACKING_URL}registrations/export/`;
  },

  async getGameHighscores(gameId) {
    const url = `${process.env.VUE_APP_API_URL}games/leaderboard?game=${gameId}`;

    const response = await axios.get(url);

    return response.data;
  },
  get userDetailsExportUrl() {
    return `${BASE_TRACKING_URL}downloads/export-user-details/`;
  },
};
