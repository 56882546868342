const BASE_URL = "https://pandora-smc2021.portal.eventcdn.net/tracking/";

export default [
  {
    name: "Holiday Readiness Guide - FR",
    href: `${BASE_URL}39/?redirect=true`,
    points: 10,
  },
  {
    name: "Holiday Readiness Guide - SP",
    href: `${BASE_URL}40/?redirect=true`,
    points: 10,
  },
  {
    name: "Holiday Readiness Guide",
    href: `${BASE_URL}41/?redirect=true`,
    points: 10,
  },
  {
    name: "Gifting 25 Percent",
    href: `${BASE_URL}42/?redirect=true`,
    points: 10,
  },
  {
    name: "Leading Through Trust Resources",
    href: `${BASE_URL}43/?redirect=true`,
    points: 10,
  },
];
