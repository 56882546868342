<template>
  <button type="button" @click="onClick" class="elevation-0 rounded-0">
    <div
      class="
        d-flex
        flex-row flex-md-column
        align-center
        justify-center
        background
        white--text
        px-4
      "
      style="height: 80px; min-width: 80px"
    >
      <v-icon large color="primary" class="pr-2 mr-md-0"
        >fas fa-chevron-left</v-icon
      >
      <p class="mb-0 mt-1 text-center" style="font-size: 12px">
        <slot>Back</slot>
      </p>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: false,
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    hasHistory() {
      return window.history.length > 2;
    },

    onClick() {
      if (this.to) {
        this.$router.push(this.to);
      } else {
        if (this.hasHistory()) {
          this.$router.go(-1);
        } else {
          this.$router.push("/");
        }
      }
    },
  },
};
</script>

<style></style>
