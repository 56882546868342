import Vue from "vue";
import Vuex from "vuex";
import UserService from "@/services/UserService";
import axios from "axios";
import { DateTime } from "luxon";

import DOWNLOADS from "@/assets/data/downloads.data";

Vue.use(Vuex);

const USER_CREDENTIALS_KEY = "credentials";

export default new Vuex.Store({
  state: {
    ssoFailed: false,
    user: null,
    leaderboard: [],
    chats: [],
    isChatOpen: false,
    colleagues: [],
    activeChatId: null,

    sessions: [],
    event: {},
    ooEvent: {},

    pointLeaderboard: [],

    availableInterests: [
      "PANDORA CREDIT CARD",
      "ENGRAVING",
      "PANDORA VALUES",
      "GEN Z",
      "PANDORA ME",
      "OMNI EXPERIENCE",
    ],

    downloads: DOWNLOADS,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
    },
    SET_USER_CREDENTIALS(state, credentials) {
      localStorage.setItem(USER_CREDENTIALS_KEY, JSON.stringify(credentials));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + credentials.access;
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Content-Type"] = "application/json";
      console.log("SETTING DEFAULT HEADER!!", credentials.access);
    },
    CLEAR_USER_CREDENTIALS() {
      localStorage.removeItem(USER_CREDENTIALS_KEY);
      location.reload();
    },
    SET_SSO_FAILED(state, data) {
      state.ssoFailed = data;
    },
    SET_LEADERBOARD(state, data) {
      state.leaderboard = data;
    },
    SET_CHATS(state, data) {
      state.chats = data;
    },
    SET_CHAT_IS_OPEN(state, data) {
      state.isChatOpen = data;
    },
    SET_COLLEAGUES(state, data) {
      state.colleagues = data;
    },
    SET_ACTIVE_CHAT_ID(state, data) {
      state.activeChatId = data;
    },
    SET_SESSIONS(state, data) {
      state.sessions = data;
    },
    SET_EVENT(state, data) {
      state.event = data;
    },
    SET_POINT_LEADERBOARD(state, data) {
      state.pointLeaderboard = data;
    },
    SET_OO_EVENT(state, data) {
      state.ooEvent = data;
    },
  },
  actions: {
    async loadCurrentUser({ commit }) {
      // Load userinfo
      const user = await UserService.getUserInfo();

      if (user) {
        // Update user photo to contain absolute url
        if (user.photo) {
          user.photo = `${process.env.VUE_APP_API_URL.replace("/api/v1/", "")}${
            user.photo
          }`;
        }

        if (user.interests) {
          user.interests = JSON.parse(user.interests);
        }

        commit("SET_USER_DATA", user);
      }

      return user || null;
    },
    async sso({ commit, getters, dispatch }) {
      // Try logging in user with stored credentials (SSO)
      let credentials = localStorage.getItem(USER_CREDENTIALS_KEY);

      if (!credentials) {
        console.log("SSO Failed - No credentials present");
        return;
      } else {
        credentials = JSON.parse(credentials);
      }

      // Login user
      commit("SET_USER_CREDENTIALS", credentials);

      let user = null;
      let isTokenExpired = false;

      try {
        user = await dispatch("loadCurrentUser");
      } catch (err) {
        user = null;

        if (err?.response?.status === 401 || err?.response?.status === 403) {
          isTokenExpired = true;
        }
      }

      if (isTokenExpired && credentials?.refresh) {
        // Try logging in with refresh token, if it exists
        try {
          const refreshResponse = await axios.post(
            `${process.env.VUE_APP_API_URL}token/refresh/`,
            { refresh: credentials.refresh }
          );

          // Login with refreshed token
          commit("SET_USER_CREDENTIALS", refreshResponse.data);

          user = await dispatch("loadCurrentUser");
        } catch (e) {
          user = null;
          console.error("Could not login with refresh token: ", e);
        }
      }

      if (user) {
        console.log("User data is:", getters.userData);

        Vue.$livehouse.eventcdn.broadcast.addChannel(
          "user",
          `pandorasmc2021_user_${user.id}`,
          {}
        );

        console.log("SSO Succeeded");
      } else {
        commit("SET_SSO_FAILED", true);
      }

      return user;
    },
    async login({ commit, dispatch }, credentials) {
      // Login user with credentials
      const userCredentialsResponse = await axios.post(
        `${process.env.VUE_APP_API_URL}token/`,
        credentials
      );
      console.log("Credentials Response:", userCredentialsResponse);
      commit("SET_USER_CREDENTIALS", userCredentialsResponse.data);

      // Load userinfo
      const user = await dispatch("loadCurrentUser");

      Vue.$livehouse.eventcdn.broadcast.addChannel(
        "user",
        `pandorasmc2021_user_${user.id}`,
        {}
      );

      return user;
    },
    logout({ commit }) {
      commit("CLEAR_USER_CREDENTIALS");
    },

    async updateLeaderboard(context) {
      const leaderboard = await Vue.$tracking.getGameHighscores(1);

      context.commit("SET_LEADERBOARD", leaderboard);

      return leaderboard;
    },
    async updateChats(context) {
      const url = `${process.env.VUE_APP_API_URL}pms/`;
      const { data } = await axios.get(url);

      context.commit("SET_CHATS", data);

      return data;
    },
    markChatUnread(context, chatId) {
      const chats = [...context.state.chats];

      const chat = chats.find((chat) => chat.id === chatId);
      const chatIndex = chats.indexOf(chat);

      chat.read = false;

      chats[chatIndex] = chat;

      context.commit("SET_CHATS", chats);
    },

    async updateChat(context, { chatId }) {
      let url = `${process.env.VUE_APP_API_URL}pms/${chatId}`;

      const { data } = await axios.get(url);

      const chats = [...context.state.chats];

      const chat = chats.find((chat) => chat.id === chatId);
      const chatIndex = chats.indexOf(chat);

      if (chatIndex !== -1) {
        chats[chatIndex] = data;
      } else {
        chats.push(data);
      }

      context.commit("SET_CHATS", chats);
    },

    async sendChat(context, { chatId, message }) {
      const url = `${process.env.VUE_APP_API_URL}pms/${chatId}`;

      await axios.post(url, { message });

      await context.dispatch("updateChat", { chatId });
    },

    toggleChat(context) {
      console.log("Toggling chat is open");
      context.commit("SET_CHAT_IS_OPEN", !context.state.isChatOpen);
    },

    // Colleagues
    async getColleagues(context) {
      const url = `${process.env.VUE_APP_API_URL}colleagues/`;

      const { data } = await axios.get(url);

      const colleagues = data.map((colleague) => {
        // Update user photo to contain absolute url
        if (colleague.photo) {
          colleague.photo = `${process.env.VUE_APP_API_URL.replace(
            "/api/v1/",
            ""
          )}${colleague.photo}`;
        }

        if (colleague.interests) {
          colleague.interests = JSON.parse(colleague.interests);
        }

        return colleague;
      });

      context.commit("SET_COLLEAGUES", colleagues);

      return colleagues;
    },

    async getColleague(context, id) {
      return context.state.colleagues.find((colleague) => colleague.id === id);
    },

    async updateSessions(context) {
      const url = `${process.env.VUE_APP_API_URL}agenda/`;

      const { data } = await axios.get(url);

      const me = context.getters.me;
      const isAdmin = context.getters.isAdmin;

      let sessions = null;

      if (me.is_oo || isAdmin) {
        sessions = data;
      } else {
        sessions = data.filter((session) => !session.oo_session);
      }

      context.commit("SET_SESSIONS", sessions);
    },
    async getSession(context, id) {
      return context.state.sessions.find((session) => session.id === id);
    },
    async updateEvent(context) {
      const mainEventUrl = `${process.env.VUE_APP_API_URL}event/1`;
      const ooEventUrl = `${process.env.VUE_APP_API_URL}event/2`;

      let mainEvent = null;
      let ooEvent = null;

      const requests = [axios.get(mainEventUrl)];

      if (context.getters.me.is_oo || context.getters.isAdmin) {
        // Add OO event to requests
        requests.push(axios.get(ooEventUrl));

        const [mainResponse, ooResponse] = await Promise.all(requests);

        mainEvent = mainResponse.data;
        ooEvent = ooResponse.data;
      } else {
        const { data } = await axios.get(mainEventUrl);

        mainEvent = data;
      }

      context.commit("SET_EVENT", mainEvent);

      if (ooEvent) {
        context.commit("SET_OO_EVENT", ooEvent);
      }
    },
    async updatePointLeaderboard(context) {
      const BASE_URL = process.env.VUE_APP_API_URL.replace("api/v1/", "");
      const url = `${BASE_URL}tracking/?limit=100`;

      const { data } = await axios.get(url);

      const pointLeaderboard = data.board;

      context.commit("SET_POINT_LEADERBOARD", pointLeaderboard);
    },

    async updateMe(context, userData) {
      const url = `${process.env.VUE_APP_API_URL}user_info/`;

      const { data } = await axios.post(url, userData);

      // Update user photo to contain absolute url
      if (data.photo) {
        data.photo = `${process.env.VUE_APP_API_URL.replace("/api/v1/", "")}${
          data.photo
        }`;
      }

      if (data.interests) {
        data.interests = JSON.parse(data.interests);
      }

      context.commit("SET_USER_DATA", data);
    },
  },
  modules: {},
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    isAdmin(state) {
      if (!state.user) return false;

      return !!(
        state.user.is_superuser ||
        state.user.is_staff ||
        state.user.is_customer_staff
      );
    },
    me(state) {
      return state.user;
    },
    unreadMessages(state) {
      return state.chats.some((chat) => chat.read === false);
    },
    currentSession(state) {
      const event = state.event;

      if (!event) return null;

      return state.sessions.find(
        (session) => session.id === event.current_session
      );
    },
    currentOOSession(state, getters) {
      if (!(getters.me.is_oo || getters.isAdmin)) {
        return null;
      }

      return state.sessions.find(
        (session) => session.id === state.ooEvent.current_session
      );
    },
    countdownTime(state, getters) {
      if (!state.event) return null;

      if (state.event.day === 1) {
        return "2021-09-14T15:00:00Z";
      }

      if (state.event.day === 2) {
        return "2021-09-15T15:00:00Z";
      }

      if (state.event.day === 3) {
        return "2021-09-16T15:30:00Z";
      }
    },
    countdownOOTime(state, getters) {
      if (!(getters.me.is_oo || getters.isAdmin)) return null;

      if (state.ooEvent.day === 2) {
        return "2021-09-15T14:30:00Z";
      }

      if (state.ooEvent.day === 3) {
        return "2021-09-16T14:30:00Z";
      }
    },
    speakers(state) {
      const speakers = {};

      state.sessions.forEach((session) => {
        session.speakers.forEach((speaker) => {
          if (!(speaker.name in speakers)) {
            speakers[speaker.name] = speaker;
          }
        });
      });

      return Object.values(speakers);
    },
  },
});
