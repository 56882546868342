<template>
  <div id="app" class="main-app">
    <lh-app>
      <v-main style="height: 100%; width: 100%">
        <router-view style="height: 100%; width: 100%"></router-view>
      </v-main>
    </lh-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  async mounted() {},

  data() {
    return {
      subs: {
        portal: null,
        user: null,
      },
    };
  },
  computed: {
    ...mapGetters(["me"]),
  },

  watch: {
    async me(newValue) {
      console.log("New User Value:", newValue);
      if (this.me) {
        // Load application data
        await this.$store.dispatch("getColleagues");
        await this.$store.dispatch("updateSessions");
        await this.$store.dispatch("updateEvent");

        // Setup listeners
        this.setupPortalListener();
        this.setupUserListener();
      }
    },
  },

  methods: {
    setupPortalListener() {
      // Setup websocket

      let channel = this.$livehouse.eventcdn.broadcast.channels?.["portal"];

      if (!channel) {
        channel = this.$livehouse.eventcdn.broadcast.addChannel(
          "portal",
          `pandora_smc_2021_4cd57e02-adb4-4874-b03f-72344bdb44c8`,
          {}
        );

        if (!this.subs.portal) {
          this.subs.portal = channel.messages$.subscribe(async (message) => {
            console.log("Websocket message is:", message);
            if (message.action === "change_state") {
              // Reload event
              await this.$store.dispatch("updateEvent");
            }
          });
        }
      }
    },
    setupUserListener() {
      // Setup websocket channel
      const channel = this.$livehouse.eventcdn.broadcast.channels["user"];

      if (!this.subs.user) {
        this.subs.user = channel.messages$.subscribe(async (message) => {
          console.log("Websocket message is:", message);
          if (message.action === "new_message") {
            // Reload chat
            await this.$store.dispatch("markChatUnread", message.sender_id);
          }
        });
      }
    },
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  background-color: #222;
}
#app {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
