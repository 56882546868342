import Vue from "vue";

// Back Button
import BackButton from "@/components/BackButton.vue";
Vue.component("AppBackButton", BackButton);

import HiddenQuestion from "@/components/HiddenQuestion.vue";
Vue.component("AppHiddenQuestion", HiddenQuestion);

import DownloadTracker from "@/components/DownloadTracker.vue";
Vue.component("DownloadTracker", DownloadTracker);
